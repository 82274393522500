<template>
	<two-cards>
			<template #header>
				<h4 class="card-title">Filtros</h4>
			</template>
			<template #one>
				<form class="mt-2" @submit.prevent="filtroVariaveis">
					<div class="form-row">
						<div class="form-group col-sm-6">
							<label for="i_nome">Variaveis *</label>
							<vue-multi-select
								class="multi-100"
								:disabled="loading"
								v-model="varSelecionadas"
								search
								historyButton
								:filters="multiSelectFilters[0]"
								searchPlaceholder="Pesquisar"
								:options="{ multi: true, labelName: 'nome' }"
								:selectOptions="variaveis"
								data-cy="variaveis"
								:btnLabel="() => varSelecionadas.length > 0  ? optionsToList(varSelecionadas).join(', '): 'Selecione'"
							/>
						</div>
						<div class="form-group col-sm-6">
							<label for="i_nome">Erros a considerar</label>
							<vue-multi-select
								class="multi-100"
								:disabled="loading"
								v-model="errosSelecionados"
								search
								historyButton
								:filters="multiSelectFilters[3]"
								searchPlaceholder="Pesquisar"
								:options="{ multi: true, labelName: 'nome' }"
								:selectOptions="erros"
								data-cy="erros"
								:btnLabel="() => errosSelecionados.length > 0  ? optionsToList(errosSelecionados).join(', '): 'Selecione'"
							/>
						</div>
						<div class="form-group col-sm-6">
							<label for="i_nome">Viagem</label>
							<vue-multi-select
								class="multi-100"
								:disabled="loading"
								v-model="viagensSelecionadas"
								search
								historyButton
								:filters="multiSelectFilters[1]"
								searchPlaceholder="Pesquisar"
								:options="{ multi: true, labelName: 'nome' }"
								:selectOptions="viagens"
								data-cy="viagens"
								:btnLabel="() => viagensSelecionadas.length > 0  ? optionsToList(viagensSelecionadas).join(', '): 'Selecione'"
							/>
						</div>
						<div class="form-group col-sm-6">
							<label for="i_nome">Movimento</label>
							<vue-multi-select
								class="multi-100"
								:disabled="loading"
								v-model="movSelecionados"
								search
								historyButton
								:filters="multiSelectFilters[2]"
								searchPlaceholder="Pesquisar"
								:options="{ multi: true, labelName: 'nome' }"
								:selectOptions="movimentos"
								data-cy="movimentos"
								:btnLabel="() => movSelecionados.length > 0  ? optionsToList(movSelecionados).join(', '): 'Selecione'"
							/>
						</div>
						
					</div>
					<div class="form-row">
						<div class="col form-group col-sm-6">
							<label for="data-inicial">Data inicial *</label>
							<datepicker
								placeholder="Início"
								id="data-inicial"
								input-class="bg-white"
								v-model="filtros.inicio"
								@closed="setFinalDate()"
								:format="formatter"
								:language="ptBR"
								:bootstrap-styling="true"
								data-cy="Data Inicial"
							></datepicker>
						</div>
						<div class="col form-group col-sm-6">
							<label for="data-final">Data final *</label>
							<datepicker
								placeholder="Fim"
								id="data-final"
								input-class="bg-white"
								v-model="filtros.fim"
								:format="formatter"
								:language="ptBR"
								:bootstrap-styling="true"
								:disabled-dates="disabledDates"
								:key="key"
								data-cy="Data Final"
							></datepicker>
						</div>
					</div>
					<div class="form-row mt-2">
						<div
							class="col-lg-2 col-md-12 mb-1"
							style="font-size: 1.5rem"
						>
							Filtros rápidos:
						</div>
						<div class="col-lg-10 col-md-12">
							<button
								type="button"
								class="col-md-2 btn rounded-pill mr-3 mb-1"
								v-for="(f, idx) in FILTROS_RAPIDOS"
								:key="idx"
								@click="btnFiltroRapido(f)"
								:class="
									filtros.rapido === f
										? 'btn-info'
										: 'btn-outline-dark'
								"
								:data-cy="f.nome"
							>
								{{ f.nome }}
							</button>
						</div>
					</div> 
					<div class="d-flex justify-content-end tcs-card-footer-action">
						<button
							class="btn btn-success tcs-btn"
							type="search"
						>
							<SearchIcon />
						</button>
					</div>
				</form>
			</template>
			<template #two>
				<div class="d-flex mb-3">
			
					<h3 class="card-tittle">Lista de variáveis</h3>
					<div class="spacer"></div>
					<button
						class="btn btn-success py-0"
						type="search"
						@click="generateCsv()"
						:disabled="lista.length < 1"
					>
						Gerar CSV
					</button>
				</div>
				<GenericList
					title="variáveis"
					ref="lista"
					data-table-state-name="Variavel"
					@edicao-variavel="mostrarEdicao"
					:cols="colunas"
					:cols-name="colunas"
					:linhas="linhas"
				/>
				
				<b-modal ref="modal" hide-footer title="Gestão de variável" >
					<AddEditVariavel
						:itemId="itemId"
						@cancelar="cancelar"
						@salvar-edicao="salvarModal"
						@salvar-adicao="salvarModal"
						@remover-variavel="remover"
					/>
				</b-modal>
			</template>
		</two-cards>
	</template>


<script>
	import TwoCards from "../templates/TwoCards.vue";
	import dayjs, { Dayjs } from "dayjs";
	import "dayjs/locale/pt-br";

	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";

	import GenericList from "@/components/GenericList";
	import AddEditVariavel from "@/components/Edit.vue";

	import Datepicker from "vuejs-datepicker";
	import { ptBR } from "vuejs-datepicker/dist/locale";

	import { TrendsService } from "@/services/trends";
	import ObjectsToCsv  from 'objects-to-csv'

	export default {
		components: {
			TwoCards,
			VueMultiSelect,
			GenericList,
			AddEditVariavel,
			Datepicker
		},

		props: {
			update: {
				type: Function
			}
		},

		data () {
			return {
				ptBR,
				itemId: 0,
				grupos: [],
				varSelecionadas: [],
				movSelecionados: [],
				errosSelecionados: [],
				viagensSelecionadas: [],
				
				variaveis: [],
				movimentos: [],
				viagens: [],
				erros: [],

				tagInput: "",
				descricaoInput: "",

				loading: false,

				lista: [],

				multiSelectFilters: [
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters(true),
				],
				FILTROS_RAPIDOS: [
					// {
					// 	nome: "Último ano",
					// 	inicio: [-1, "year"],
					// 	fim: [0, "years"],
					// 	agrupamento: "Dia",
					// 	intervalo: 3600
					// },
					{
						nome: "Último mês",
						inicio: [-1, "month"],
						fim: [0, "months"],
						agrupamento: "Dia",
						intervalo: 3600
					},
					{
						nome: "Última semana",
						inicio: [-1, "week"],
						fim: [0, "weeks"],
						agrupamento: "Hora",
						intervalo: 1800
					},
					{
						nome: "Último dia",
						inicio: [-1, "day"],
						fim: [0, "days"],
						agrupamento: "Hora",
						intervalo: 300
					},
				],

				filtros: {
					inicio: null,
					fim: null,
					agrupamento: null,
					rapido: 0
				},
				trendsService: new TrendsService(),

				disabledDates: {from: new Date(2022,0,1)},

				key: 0

			};

		},

		computed: {
			linhas () {
				return this.lista.map(row => ({
					href: { path: row.id },
					cols: Object.values(row)
				}));
			},
			colunas () {
				try {
					return Object.keys(JSON.parse(JSON.stringify(this.lista))[0]);
				} catch {
					return [];
				}

			}
		},

		async mounted () {
			this.filtroRapido(this.FILTROS_RAPIDOS[0]);
			this.movSelecionados = [];
			
			this.varSelecionadas = [{nome: "viagem", disabled:true},{nome: "composicao", disabled:true}];
			this.variaveis = [{nome: "viagem", disabled:true},
				{nome: "composicao", disabled:true},
				{nome: "movimento", disabled:false},
				{nome: "placa", disabled:false},
				{nome: "erro", disabled:false},
				{nome: "camera", disabled:false},
				{nome: "balanca", disabled:false},
				{nome: "qtd_composicoes", disabled:false},
				{nome: "sujidade_composicao", disabled:false},
				{nome: "sujidade_caminhao", disabled:false},
				{nome: "data_hora", disabled:false},
				{nome: "caminho_arquivo", disabled:false},];
			
			this.errosSelecionados = [];
			this.updateLists();
		},

		methods: {
			async generateCsv() {
				const csv = new ObjectsToCsv(JSON.parse(JSON.stringify(this.lista)));

				var csv_string = await csv.toString()

				let csvContent = "data:text/csv;charset=utf-8," + csv_string;
				console.log("csv gerado")
				var encodedUri = encodeURI(csvContent);
				var link = document.createElement("a");
				link.setAttribute("href", encodedUri);
				link.setAttribute("download", "relatorio_barkfinder.csv");
				document.body.appendChild(link); 
				link.click(); 
			},

			fecharModal () {
				this.$refs.modal.hide();
			},

			mostrarEdicao (itemId) {
				this.itemId = itemId;
				this.$refs.modal.show();
			},

			mostrarAdicao () {
				this.itemId = 0;
				this.$refs.modal.show();

				this.fecharModal();
			},

			cancelar () {
				this.loading = false;
				this.fecharModal();
			},

			salvarModal () {
				this.loading = true;

				this.filtroVariaveis();
				this.fecharModal();
			},

			async remover () {
				this.filtroVariaveis();
				this.fecharModal();
			},

			filtroRapido (fr) {
				this.filtros.rapido = fr;
				if (fr.inicio)
					this.filtros.inicio = dayjs()
						.add(...fr.inicio)
						.toDate();

				if (fr.fim)
					this.filtros.fim = dayjs()
						.add(...fr.fim)
						.toDate();
			},

			btnFiltroRapido (fr) {
				if (this.filtros.rapido !== fr) this.filtroRapido(fr);
			},

			formatter (date) {
				return dayjs(date).locale("pt-br").format("D [de] MMMM [de] YYYY");
			},

			getMultiSelectFilters (feminino) {
				return [{
					nameAll: `Selecionar tod${feminino ? "a" : "o"}s`,
					nameNotAll: `Desselecionar tod${feminino ? "a" : "o"}s`,
					func () {
						return true;
					}
				}];
			},
			optionsToList (options) {
				return options.map(el => el.nome);
			},

			async updateLists(){
				this.movimentos = await this.trendsService.getOptions("movimento","metadata")
				this.movimentos = this.movimentos.map(el => ({'nome': el.movimento,disabled:false}))

				this.viagens = await this.trendsService.getOptions("trip_number","metadata")
				this.viagens = this.viagens.map(el => ({'nome': el.trip_number,disabled:false}))

				this.erros = await this.trendsService.getOptions("error","dirtness")
				this.erros = this.erros.map(el => ({'nome': el.error,disabled:false}))

			},
			async filtroVariaveis () {
				try {
					this.loading = true;
					const inicio = dayjs(this.filtros.inicio).startOf("day").toDate();
					const fim = dayjs(this.filtros.fim).endOf("day").toDate();
					let filters={"movimento":this.optionsToList(this.movSelecionados),"viagem":this.optionsToList(this.viagensSelecionadas),"erros":this.optionsToList(this.errosSelecionados)};
					this.lista = await this.trendsService.getData(this.optionsToList(this.varSelecionadas),filters,inicio, fim, 0);
					this.loading = false;
				} catch (err) {
					console.log(err);
					this.$swal({
						title: "Falha ao fazer a busca!",
						html: `<p>${err.response ? err.response.data.error : err.message}</p>`,
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
				}
			},
			setFinalDate()
			{
				this.filtros.fim=this.filtros.inicio
				// let date = new Date(this.filtros.inicio)
				console.log("AQUI -1")
				let date = new Date(this.filtros.inicio)
				console.log(date)
				date.setMonth(date.getMonth()+1)
				console.log(date)
				this.disabledDates.from=date
				this.disabledDates.to=this.filtros.inicio
				this.key += 1
			}
		}
	};
	</script>
